<template>
  <a-drawer
    width="800"
    :label-col="4"
    :wrapper-col="14"
    :visible="open"
    @close="onClose"
    :destroyOnClose="true">
    <a-divider orientation="left">
      <b>详情</b>
    </a-divider>
    <a-descriptions bordered :column="2" size="middle">
      <a-descriptions-item label="订单号">{{ form.orderNo }}</a-descriptions-item>
      <a-descriptions-item label="退款编号">{{ form.refundSn }}</a-descriptions-item>

      <a-descriptions-item label="添加时间">{{ form.createTime }}</a-descriptions-item>
      <!-- <a-descriptions-item label="用户名称">{{ form.userIdName }}</a-descriptions-item> -->
      <a-descriptions-item label="用户手机号">{{ form.userPhoneYh }}</a-descriptions-item>
      <a-descriptions-item label="绑定人名称">{{ form.userName }}</a-descriptions-item>
      <a-descriptions-item label="绑定人手机号">{{ form.userPhone }}</a-descriptions-item>

      <a-descriptions-item label="水表编号">{{ form.devNeea }}</a-descriptions-item>
      <a-descriptions-item label="省市区名称">{{ form.pcaName }}</a-descriptions-item>
      <a-descriptions-item label="详细地址">{{ form.addressName }}</a-descriptions-item>

      <a-descriptions-item label="退款类型">
        <a-tag color="blue" v-if="form.type == 0">未退款</a-tag>
        <a-tag color="orange" v-else-if="form.type == 1">部分退款</a-tag>
        <a-tag color="red" v-else-if="form.type == 2">全部退款</a-tag>
      </a-descriptions-item>
      <a-descriptions-item label="退款状态">
        <a-tag color="orange" v-if="form.status == 0">待处理</a-tag>
        <a-tag color="green" v-if="form.status == 1">退款成功</a-tag>
        <a-tag color="blue" v-else-if="form.status == 2">退款已处理</a-tag>
        <a-tag color="red" v-else-if="form.status == 3">退款关闭</a-tag>
        <a-tag color="cyan" v-else-if="form.status == 4">退款失败</a-tag>
      </a-descriptions-item>

      <a-descriptions-item label="订单总金额">{{ form.orderMoney }}</a-descriptions-item>
      <a-descriptions-item label="退款金额">{{ form.refundAmount }}</a-descriptions-item>

      <a-descriptions-item label="申请时间">{{ form.applyTime }}</a-descriptions-item>
      <a-descriptions-item label="受理时间">{{ form.handelTime }}</a-descriptions-item>
      <a-descriptions-item label="退款时间">{{ form.refundTime }}</a-descriptions-item>
      <a-descriptions-item label="拒绝时间">{{ form.rejectTime }}</a-descriptions-item>
      <a-descriptions-item label="撤销时间">{{ form.cancelTime }}</a-descriptions-item>

      <a-descriptions-item label="备注">{{ form.remark }}</a-descriptions-item>
    </a-descriptions>
  </a-drawer>
</template>

<script>

import { orderRefundInfo } from '@/api/pages/payment-refund'

export default {
  name: 'CreateForm',
  props: {
  },
  data () {
    return {
      submitLoading: false,
      form: {},
      open: false
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    onClose () {
      this.open = false
    },
    info (id) {
      orderRefundInfo({ id }).then(response => {
        this.form = response.data
        this.open = true
      })
    }
  }
}
</script>
<style lang="less" scoped>
  .ant-upload-preview {
    position: relative;
    width: 100px;
    height: 100px;
    .upload-icon {
      position: absolute;
      top: 0;
      right: 10px;
      font-size: 1.4rem;
      padding: 0.5rem;
      background: rgba(222, 221, 221, 0.7);
      border: 1px solid rgba(0, 0, 0, 0.2);
    }
    .mask {
      position: absolute;
      background: rgba(0,0,0,0.4);
      cursor: pointer;
      transition: opacity 0.4s;
      &:hover {
        opacity: 1;
      }
      i {
        font-size: 2rem;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -1rem;
        margin-top: -1rem;
        color: #d6d6d6;
      }
    }
    img, .mask {
      width: 100%;
      max-width: 180px;
      height: 100%;
      overflow: hidden;
      object-fit: contain;
    }
  }
</style>
